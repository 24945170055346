import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button, Divider, FormControl, InputLabel, List, MenuItem, Select, Typography } from '@mui/material';
import logo from '../../../img/logo.jpeg';
import MapIcon from '@mui/icons-material/Map';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import Drawer from './Drawer';
import { MainListItems, secondaryListItems } from './listItems';
import useAuth from '../Auth/useAuth';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from './AppBar';
function HeaderUser({ title,subTitle }) {

    const [open, setOpen] = React.useState(true);
  
    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Box>
            <AppBar position="absolute" open={open} sx={{ zIndex: '10' }}>
                <Toolbar
                    sx={{ justifyContent: 'space-between', overflowX: 'auto' }} xs={{ borderBottom: 1, borderColor: 'divider', top: 0 }} className="bg-white px-4" >
                    <div className='d-flex w-100 border-bottom pb-3'>

                        <Box sx={{ pt: 5 }} xs={{ pt: 1 }} className="px-4 me-auto">
                            <h2 className='color-primary fw-bolder'>{title}</h2>
                            <Box className="fs-sm text-secondary">
                                {subTitle}
                            </Box>
                        </Box>
                        <Box sx={{ pt: 5 }} xs={{ pt: 1 }} className="pe-4">
                            <Button className='bg-transparent border rounded text-dark p-1' sx={{ minWidth: 'auto' }}>
                                <SearchIcon />
                            </Button>
                        </Box>
                    </div>
                </Toolbar>
            </AppBar >
          
            <Drawer className='pr-4 position-fixed' variant="permanent" open={open} style={{ height: '100vh', zIndex: '10' }}>
                <Box className="" sx={{ pt: 4 }} xs={{ pt: 1 }}>
                    <Box className="me-auto d-flex fw-bold color-primary align-items-center" sx={{ gap: '6px', fontSize: '36px' }}>
                        <MapIcon sx={{ fontSize: '48px' }} />
                        <p className='m-0 '>GeoTani</p>
                    </Box>
                    <Box className="text-center fw-bold text-dark">
                        Provinsi Aceh
                    </Box>
                    <Box>
                        <List className='my-4'><MainListItems /></List>
                        <List>{secondaryListItems}</List>
                    </Box>
                </Box>

            </Drawer>
            <Drawer className='px-4' variant='permanent' sx={{marginRight:'200px'}}></Drawer>
        </Box>

    );
}

export default HeaderUser;