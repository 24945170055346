import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { apiContext } from '../api';
const mdTheme = createTheme();

export default function Login() {
    const [loading, setLoading] = React.useState("");
    const { API_URL } = React.useContext(apiContext);
    const navigate = useNavigate();
    const [validation, setValidation] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        await axios.post(API_URL + '/login', data)
            .then((response) => {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('user_id', response.data.id);
                localStorage.setItem('isLoggedIn', true);
                window.open('/user/dashboard', "_self")
            })
            .catch((error) => {
                setValidation(true);
                setLoading(false);
            })
    };
    return (
        <ThemeProvider theme={mdTheme} >
           
            <div sx={{ mt: 4, mb: 4, padding: '0 30%' }} style={{height:'100vh',alignItems:'center'}}className="d-flex" >
                
                <div className={`align-items-center  ${isMobile ? 'px-4' :'mx-auto'}`} >
                    
                    <div className={`card-box border w-100 main-color rounded-3 ${isMobile? 'm-0':''}`} style={{ backgroundColor: '#0A318A' }}>
                        <div className=' bg-white rounded-3 text-center shadow-lg' style={{ padding: '30px 40px' }}>
                            <h3>Sign In</h3>
                            <Box component={'form'} onSubmit={handleSubmit} noValidate  sx={{ mt: 1 }} className="px-2">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <div className='text-center'>
                                    {
                                        validation && <span className='text-danger text-center fs-6'>Akun Tidak Ditemukan</span>
                                    }
                                </div>
                                <p>Forgot your password?</p>
                                <Button
                                 
                                    disabled={loading ? true : false}
                                    type='submit'
                                    fullWidth
                                    variant="contained"
                                    className=' text-white rounded-pill mt-1'
                                    style={{ backgroundColor: '#23bd9d' }}
                                    sx={{ height: '47px', width: '147px' }}>
                                    {loading && <CircularProgress sx={{ color: 'white', width: '20px !important', height: '20px !important', marginRight: 2 }} />} Sign In
                                </Button >
                            </Box>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>
        </ThemeProvider>
    );
}
