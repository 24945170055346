import { Box } from "@mui/system";
import HeaderUser from "./nav/HeaderUser";
import { ReactComponent as Jagung } from '../../img/jagung.svg';
import { ReactComponent as Padi } from '../../img/padi.svg';
import SoapIcon from '@mui/icons-material/Soap';
import Map from "../components/Map";
import { useState } from "react";
import Copyright from "./nav/Copyright";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useAuth from "./Auth/useAuth";
export default function Dashboard() {
    const [kategori, setKategori] = useState("jagung");
    const [open, setOpen] = useState(false);
    const [peta, setPeta] = useState(false);
    const { user } = useAuth();
    return (
        <Box className="d-flex">
            <HeaderUser title={'Dashboard'} subTitle={"Selamat Datang, "+user.name}/>
            <Box component={'main'} className="me-5 w-100" style={{ marginTop: '150px' }}>
                <Box className="row mb-5" sx={{ gap: '12px' }}>
                    <Box className="rounded shadow d-flex p-3 col-md-4" sx={{ gap: '22px' }}>
                        <Jagung width={70} />
                        <Box className="w-100 d-flex flex-column align-items-end">
                            <span className="text-secondary">Total Produksi Jagung</span>
                            <h3 className="m-0 fw-bolder">Ton/Ha</h3>
                        </Box>
                    </Box>
                    <Box className="rounded shadow d-flex p-3 col-md-4" sx={{ gap: '22px' }}>
                        <Padi width={70}/>
                        <Box className="w-100 d-flex flex-column align-items-end">
                            <span className="text-secondary">Total Produksi Padi</span>
                            <h3 className="m-0 fw-bolder">Ton/Ha</h3>
                        </Box>
                    </Box>
                    <Box className="rounded shadow d-flex p-3 col" sx={{ gap: '22px' }}>
                        <SoapIcon className="color-primary" sx={{ fontSize: 40 }} />
                        <Box className="w-100 d-flex flex-column align-items-end">
                            <span className="text-secondary">Total Bibit Unggul Tersedia</span>
                            <h3 className="m-0 fw-bolder">Ton/Ha</h3>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <h3 className="fw-bold">Data Setiap Daerah</h3>
                    <p className="text-secondary mb-4">Silahkan pilih daerah yang ingin dilihat detailnya</p>
                    <Map open={open} kategori={kategori} setOpen={setOpen} peta={peta} setPeta={setPeta} fullHeight={false} border={true} />
                </Box>
                <Box className="mt-5 row">
                    <Box className="col-md-3">
                        <h3 className="fw-bolder">Statistik</h3>
                        <span className="text-secondary">Perkembangan pengunjung yang tercatat pada website.</span>
                        
                    </Box>
                    <Box className="col row mb-5" sx={{ gap: '12px' }}>
                        <Box className="rounded shadow p-3 col-md-4" sx={{ gap: '22px' }}>
                            <Box className="w-100 d-flex">
                                <h3 className="m-0 fw-bolder">1</h3>
                                <span className="text-up"><ArrowDropUpIcon/>%1</span>
                            </Box>
                            <span className="text-secondary">Jumlah<br /> Pengunjung Hari ini</span>
                        </Box>
                        <Box className="rounded shadow p-3 col-md-4" sx={{ gap: '22px' }}>
                            <Box className="w-100 d-flex">
                                <h3 className="m-0 fw-bolder">1</h3>
                                <span className="text-up"><ArrowDropUpIcon />%1</span>
                            </Box>
                            <span className="text-secondary">Jumlah<br /> Pengunjung Bulan Ini</span>
                        </Box>
                        <Box className="rounded shadow  p-3 col" sx={{ gap: '22px' }}>
                            <Box className="w-100 d-flex ">
                                <h3 className="m-0 fw-bolder">1</h3>
                                <span className="text-up"><ArrowDropUpIcon />%1</span>
                            </Box>
                            <span className="text-secondary">Jumlah<br /> pengunjung Tahun Ini</span>
                        </Box>
                    </Box>
                </Box>
                <Copyright />
            </Box>
        </Box>
    )
}