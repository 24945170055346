import { ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";

export default function ProgressStep({ list, current, onChangeCurrent }) {
    return (
        <Box className="progressbar">
            {
                list.map((item, index) => {
                    return (
                        <ListItemButton key={index} onClick={() => onChangeCurrent(index + 1)} className={`my-2 ${index + 1 === current ? 'active' : ''} ${item.complete ? 'complete' : ''}`}>
                            <ListItemText primary={<span className="fw-bold">{item.name}</span>} />
                        </ListItemButton>
                    )
                })
            }
        </Box>
    )
}