import React, { useEffect, useRef, useState } from 'react';
import { Box, Container } from '@mui/system';
import Header from './nav/Header';

import 'reactjs-popup/dist/index.css';
import { Button} from '@mui/material';
import Map from './components/Map';

export default function Index() {
    const [kategori, setKategori] = useState("jagung");
    const [open, setOpen] = useState(false);
    const [peta, setPeta] = useState(false);

    return (
        <Box>
            <Header setKategori={setKategori} />
            <Box onClick={() => { setOpen(false); setPeta(false) }}>
                <Map open={open} kategori={kategori} setOpen={setOpen} peta={peta} setPeta={setPeta} fullHeight={true}/>
              
                {/* <Box style={{ backgroundColor: "rgb(157,177,108)" }}> */}
                <Box className="container" style={{ paddingTop: "5rem" }} >
                    <Box className="row" >
                        <Box className="fs-6 col-md-6 lh-lg" >
                            <h2 className='mb-3'>Tentang Geotani</h2>
                            Portal Geotani Aceh merupakan pilot web menggunakan platform Geographic Information System (GIS) yang menampilkan Hasil Kajian Percepatan Pembangunan Pertanian Aceh Berbasis Informasi Digital, kerjasama antara Universitas Syiah Kuala dengan Distanbun Provinsi Aceh. Informasi yang ditampilkan merupakan hasil survei lapangan pada 8 kabupaten di Provinsi Aceh dan data sekunder terkait dari Distanbun Provinsi Aceh.
                        </Box>
                        <Box className="d-flex col-md-6 p-5">
                            {/* <img src={MapIcon} width={400} /> */}
                            <Box className="mx-auto p-4 rounded" style={{ backgroundColor: '#80808030' }}>
                                <h4>Tabulasi Data</h4>
                                <p>Klik untuk mencari data geospasial yang diterbitkan oleh pengguna lain, organisasi dan sumber-sumber publik.</p>
                                <Button variant='contained' color='success' className='mt-1'>Telusuri data</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="bg-success py-4 text-white">
                    <Box className="container">
                        <Box className="row text-white" sx={{ gap: '12px' }}>
                            <Box className="col-md-6 ">
                                <h4 className='border-bottom d-inline-block  mb-3'>
                                    TENTANG
                                </h4>
                                <Box sx={{ textAlign: 'justify' }}>
                                    Tentang Dinas Pertanian dan Perkebunan Aceh
                                    Dinas Pertanian Tanaman Pangan Aceh sebagai Organisasi Pemerintahan di Daerah Aceh telah berdiri dan terbentuk sejak sebelum tahun 1935 yang lalu pada masa pemerintahan Kolonial Belanda di Indonesia dan terus berlanjut kemasa Pemerintahan Penduduk Jepang (1942- 1945), dan hingga sampai saat sekarang ini.
                                </Box>
                            </Box>
                            <Box className="col-md-4 ">
                                <h4 className='border-bottom d-inline-block mb-3'>
                                    KONTAK
                                </h4>
                            </Box>
                        </Box>
                    </Box>
                </Box>
         
                {/* </Box> */}

            </Box>
        </Box>
    )
}