import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step4() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [polaData, setPolaData] = useState([]);
    const [pola_tanam_id1, setPola1] = useState('');
    const [pola_tanam_id2, setPola2] = useState('');
    const [itensitas, setItensitas] = useState('');
    const [itensitas_5tahun, setItensitasTahun] = useState('');
    const [dampak_usaha, setDampakUsaha] = useState('');
    const [dampak_pola, setDampakPola] = useState('');
    const { axios } = useContext(apiContext);
    const fetchData = async () => {
        const { data } = await axios.get(`/surveyiklim-get/${id}`);
        if (data.length) {
            setPola1(data.pola_tanam_id1);
            setPola2(data.pola_tanam_id2);
            setItensitas(data.itensitas);
            setItensitasTahun(data.itensitas_5tahun);
            setDampakUsaha(data.dampak_usaha);
            setDampakPola(data.dampak_pola);
        }
    }
    const fetchPola = async () => {
        const { data } = await axios.get(`/pola`);
        if (data) {
            setPolaData(data);
        }
    }
    useEffect(() => {
        fetchData();
        fetchPola();
    }, [])

    const submitForm = async () => {
        const data = {
            pola_tanam_id1: pola_tanam_id1,
            pola_tanam_id2: pola_tanam_id2,
            itensitas: itensitas,
            itensitas_5tahun: itensitas_5tahun,
            dampak_usaha: dampak_usaha,
            dampak_pola: dampak_pola,
            petani_id: id
        }
        await axios.post('/surveyiklim', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/5`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana pendapat saudara tentang perubahan iklim (intensitas hujan, suhu) di lokasi usahatani padi/jagung</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Perubahan Iklim</InputLabel>
                    <Select
                        value={itensitas}
                        label="itensitas"
                        onChange={(e) => setItensitas(e.target.value)}
                    >
                        <MenuItem value={'j'}>Jarang</MenuItem>
                        <MenuItem value={'s'}>Sering</MenuItem>
                        <MenuItem value={'ss'}>Sangat Sering</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana pendapat anda tentang perubahan iklim (intensitas hujan, suhu) dalam 5 tahun terakhir terhadap usahatani padi/jagung</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Perubahan Iklim</InputLabel>
                    <Select
                        value={itensitas_5tahun}
                        label="itensitas_5tahun"
                        onChange={(e) => setItensitasTahun(e.target.value)}
                    >
                        <MenuItem value={'j'}>Jarang</MenuItem>
                        <MenuItem value={'s'}>Sering</MenuItem>
                        <MenuItem value={'ss'}>Sangat Sering</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana pendapat anda tentang dampak dari perubahan iklim (intensitas hujan, suhu) terhadap usahatani (padi/jagung)(kekurangan air sungai, suhu meningkat, hama dan penyakit dll)</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Dampak Iklim</InputLabel>
                    <Select
                        value={dampak_usaha}
                        label="dampak_usaha"
                        onChange={(e) => setDampakUsaha(e.target.value)}
                    >
                        <MenuItem value={'tb'}>Tidak Berubah</MenuItem>
                        <MenuItem value={'b'}>Berubah</MenuItem>
                        <MenuItem value={'kb'}>Kurang Berubah</MenuItem>
                        <MenuItem value={'sb'}>Sangat Berubah</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana dampak perubahan iklim (intensitas hujan, suhu) terhadap pola tanam padi/jangung</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Dampak Pola Tanam</InputLabel>
                    <Select
                        value={dampak_pola}
                        label="dampak_pola"
                        onChange={(e) => setDampakPola(e.target.value)}
                    >
                        <MenuItem value={'tb'}>Tidak Berpengaruh</MenuItem>
                        <MenuItem value={'b'}>Berpengaruh</MenuItem>
                        <MenuItem value={'kb'}>Kurang Berpengaruh</MenuItem>
                        <MenuItem value={'sb'}>Sangat Berpengaruh</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Sebutkan pola tanam padi/jagung yang di lokasi saudara
                    Misalnya: januari sd mei dan juli sd november</p>
                <Box className="row">
                    <Box className="col-md-5">
                        <FormControl fullWidth>
                            <InputLabel>Pilih Pola Tanam</InputLabel>
                            <Select
                                value={pola_tanam_id1}
                                label="pola_tanam_id2"
                                onChange={(e) => setPola1(e.target.value)}
                            >
                                {
                                    polaData?.map((item,index)=>{
                                        return(
                                            <MenuItem key={index} value={item.kode}>{item.pola}</MenuItem>
                                        )
                                    })
                                }
                       
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className="col-md-2 text-center align-self-center">
                        <span className="text-secondary">Sd</span>
                    </Box>
                    <Box className="col-md-5">
                        <FormControl fullWidth>
                            <InputLabel>Pilih Pola Tanam</InputLabel>
                            <Select
                                value={pola_tanam_id2}
                                label="pola_tanam_id2"
                                onChange={(e) => setPola2(e.target.value)}
                            >
                                {
                                    polaData?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.kode}>{item.pola}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}