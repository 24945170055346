import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

function Copyright(props) {
    return (
        <Box className="py-4">
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                Dinas Pertanian dan Perkebunan{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>
        
    );
}
export default Copyright;