import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Api from "./api";
import Index from './pages/Index';
import Login from "./pages/Login";
import Profile from "./pages/user/Auth/Profiles";
import Dashboard from "./pages/user/Dashboard";
import Data from "./pages/user/Data";
import DataList from "./pages/user/DataList";
import PrivateRoute from "./pages/user/PrivateRoute";

function App() {

  return (
    <>
      <Api>
        <Router>
          <Profile>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path='/user/dashboard' element={<Dashboard />} />
                <Route path='/user/datalist' element={<DataList />} />
                <Route path='/user/data/' >
                  <Route path=':id' element={<Data />} />
                  <Route path=':id/:step' element={<Data />} />
                  <Route path='' element={<Data />} />
                </Route>
              </Route>
            </Routes>
          </Profile>
          <Routes>
            <Route>
              <Route exact path='/' element={<Index />} />
              <Route exact path='/login' element={<Login />} />
            </Route>
          </Routes>
        </Router>
      </Api>

    </>

  );
}

export default App;
