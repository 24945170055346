import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step5() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [polaData, setPolaData] = useState([]);
    const [kebutuhan, setKebutuhan] = useState('');
    const [urea, setUrea] = useState('');
    const [mutu, setMutu] = useState('');
    const [p, setP] = useState('');
    const [npk, setNpk] = useState('');
    const [herbisida, setHerbisida] = useState('');
    const [pupuk_kandang, setPupukKandang] = useState('');
    const { axios } = useContext(apiContext);
    const fetchData = async () => {
        const { data } = await axios.get(`/pupuk-get/${id}`);
        if (data.length) {
            console.log(data)
            setKebutuhan(data.kebutuhan);
            setUrea(data.urea);
            setMutu(data.mutu);
            setP(data.p);
            setNpk(data.npk);
            setHerbisida(data.herbisida);
            setPupukKandang(data.pupuk_kandang);
        }
    }
   
    useEffect(() => {
        fetchData();
    }, [])

    const submitForm = async () => {
        const data = {
            kebutuhan: kebutuhan,
            urea: urea,
            mutu: mutu,
            p: p,
            npk: npk,
            herbisida: herbisida,
            pupuk_kandang: pupuk_kandang,
            petani_id: id
        }
        await axios.post('/pupuk', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/6`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <Box className="row">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Kebutuhan/Musim</p>
                    <TextField fullWidth type="number" onChange={(e) => setKebutuhan(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Urea</p>
                    <TextField fullWidth type="number" onChange={(e) => setUrea(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Nama</p>
                    <FormControl fullWidth>
                        <InputLabel>Mutu Benih</InputLabel>
                        <Select
                            value={mutu}
                            label="mutu"
                            onChange={(e) => setMutu(e.target.value)}
                        >
                            <MenuItem value="Bersertifikat">Bersertifikat</MenuItem>
                            <MenuItem value="Tidak Bersertifikat">Tidak Bersertifikat</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">P</p>
                    <TextField fullWidth type="number" onChange={(e) => setP(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>

            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">NPK</p>
                    <TextField fullWidth type="number" onChange={(e) => setNpk(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Herbisida</p>
                    <TextField fullWidth type="number" onChange={(e) => setHerbisida(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pupuk Kandang</p>
                    <TextField fullWidth type="number" onChange={(e) => setPupukKandang(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}