import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Link, useLocation } from 'react-router-dom';
export const MainListItems = ()=> {
    const location = useLocation();
    return(
        <div>
            <Link to={'/user/dashboard'} className='text-decoration-none'>
                <ListItemButton className={`my-2 ${(location.pathname.indexOf('/user/dashboard') !== -1)? 'text-white bg-list rounded':'text-secondary'}`}>
                    <ListItemIcon className={(location.pathname.indexOf('/user/dashboard') !== -1) ? 'text-white' : 'text-secondary'}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </Link>
            <Link to={'/user/datalist'} className='text-decoration-none'>
                <ListItemButton className={`my-2 ${(location.pathname.indexOf('/user/data') !== -1)? 'text-white bg-list rounded':'text-secondary'}`}>
                    <ListItemIcon className={(location.pathname.indexOf('/user/data') !== -1) ? 'text-white' : 'text-secondary'}>
                        <StorageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data" />
                </ListItemButton>
            </Link>
            <Link to={'/user/data-grafik'} className='text-decoration-none'>
                <ListItemButton className={`my-2 ${(location.pathname.indexOf('/user/data-grafik') !== -1)? 'text-white bg-list rounded':'text-secondary'}`}>
                    <ListItemIcon className={(location.pathname.indexOf('/user/data-grafik') !== -1) ? 'text-white' : 'text-secondary'}>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Grafik" />
                </ListItemButton>
            </Link>
            <Link to={'/user/tabulasi-data'} className='text-decoration-none'>
                <ListItemButton className={`my-2 ${(location.pathname.indexOf('/user/tabulasi-data') !== -1)? 'text-white bg-list rounded':'text-secondary'}`}>
                    <ListItemIcon className={(location.pathname.indexOf('/user/tabulasi-data') !== -1) ? 'text-white' : 'text-secondary'}>
                        <ArchiveIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tabulasi Data" />
                </ListItemButton>
            </Link>
         
        </div>
    )
    
}

export const secondaryListItems = (
    <div>
        <Link to={'/logout'} className='text-decoration-none text-danger'>
            <ListItemButton className='bg-danger rounded text-white'>
            <ListItemIcon className='text-white'>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
        </ListItemButton>
        </Link>
    </div>
);