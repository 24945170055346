import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step6() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [pestisida, setPestisida] = useState('');
    const [olah_tanah, setOlahTanah] = useState('');
    const [pemeliharaan, setPemeliharaan] = useState('');
    const [panen, setPanen] = useState('');
    const [penyimpanan, setPenyimpanan] = useState('');
    const { axios } = useContext(apiContext);
    const fetchData = async () => {
        const { data } = await axios.get(`/infolanjutan-get/${id}`);
        if (data.length) {
            setPestisida(data.pestisida);
            setOlahTanah(data.olah_tanah);
            setPemeliharaan(data.pemeliharaan);
            setPanen(data.panen);
            setPenyimpanan(data.penyimpanan);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const submitForm = async () => {
        const data = {
            pestisida: pestisida,
            olah_tanah: olah_tanah,
            pemeliharaan: pemeliharaan,
            panen: panen,
            penyimpanan: penyimpanan,
            petani_id: id
        }
        await axios.post('/infolanjutan', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/7`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <Box className="row">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pestisida</p>
                    <TextField fullWidth type="number" onChange={(e) => setPestisida(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Kg/Ha/Musim Tanam</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Olah Tanah</p>
                    <TextField fullWidth type="number" onChange={(e) => setOlahTanah(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Orang/Hari Kerja</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pemeliharaan</p>
                    <TextField fullWidth type="number" onChange={(e) => setPemeliharaan(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Orang/Hari Kerja</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Panen</p>
                    <TextField fullWidth type="number" onChange={(e) => setPanen(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Orang/Hari Kerja</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Penyimpanan</p>
                    <FormControl fullWidth>
                        <InputLabel>Penyimpanan</InputLabel>
                        <Select
                            value={penyimpanan}
                            label="Penyimpanan"
                            onChange={(e) => setPenyimpanan(e.target.value)}
                        >
                            <MenuItem value="Sebagian Dijual">Sebagian Dijual</MenuItem>
                            <MenuItem value="Seluruhnya Dijual">Seluruhnya Dijual</MenuItem>
                            <MenuItem value="Gudang">Gudang</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}