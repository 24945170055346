import { Box } from "@mui/system";
import HeaderUser from "./nav/HeaderUser";

import SoapIcon from '@mui/icons-material/Soap';
import Map from "../components/Map";
import { useEffect, useState } from "react";
import Copyright from "./nav/Copyright";
import ProgressStep from "../components/ProgressStep";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Step1 from "../components/form/komoditi/Step1";
import { useNavigate, useParams } from "react-router-dom";
import Step2 from "../components/form/komoditi/Step2";
import Step3 from "../components/form/komoditi/Step3";
import Step4 from "../components/form/komoditi/Step4";
import Step5 from "../components/form/komoditi/Step5";
import Step6 from "../components/form/komoditi/Step6";
import Step7 from "../components/form/komoditi/Step7";
import Step8 from "../components/form/komoditi/Step8";
import Step9 from "../components/form/komoditi/Step9";
export default function Data() {
    const { id, step } = useParams();
    const navigate = useNavigate();
    const [komoditi, setKomoditi] = useState(1);
    const [open, setOpen] = useState(false);
    const [peta, setPeta] = useState(false);
    const [current, setCurrent] = useState(step ? parseInt(step) : 1);

    const onChangeCurrent = (current) => {
        setCurrent(current);
        navigate(`/user/data/${id}/${current}`);
    }
    const itemList = [
        {
            name: 'Data Petani',
            complete: true
        },
        {
            name: 'Biodata Petani',
            complete: true
        },
        {
            name: 'Survey Lahan',
            complete: false
        },
        {
            name: 'Survey Iklim',
            complete: false
        },
        {
            name: 'Data Pupuk',
            complete: false
        },
        {
            name: 'Informasi Tanam',
            complete: false
        },
        {
            name: 'Hasil Panen',
            complete: false
        },
        {
            name: 'Informasi Modal',
            complete: false
        }
    ]
    return (
        <Box className="d-flex">
            <HeaderUser title={'Data'} subTitle={'Silahkan lakukan update data komoditi di tiap daerah'} />
            <Box component={'main'} className="me-5 w-100 pb-5 " style={{ marginTop: '150px' }}>
                <Box className="row ">
                    <Box className="col-md-4" >
                        <Box className="py-4 ps-4" sx={{ backgroundColor: '#f0fff9', borderRadius: '1.3rem' }}>
                            <h3 className="fw-bold">Masukkan Data</h3>
                            <p className="text-secondary">Perubahan akan tersimpan setelah klik tombol “Selanjutnya”.</p>
                            <ProgressStep list={itemList} current={current} onChangeCurrent={onChangeCurrent} />
                        </Box>

                    </Box>
                    <Box className=" col-md-7 pt-4 ps-3">
                        {
                            step == null || step === '1' ? < Step1 komoditi={komoditi} setKomoditi={setKomoditi} /> :
                                step === '2' ? < Step2 /> :
                                    step === '3' ? < Step3 /> :
                                        step === '4' ? < Step4 /> :
                                            step === '5' ? < Step5 /> :
                                                step === '6' ? < Step6 /> :
                                                    step === '7' ? < Step7 /> :
                                                        step === '8' ? < Step8 /> :
                                                            step === '9' ? < Step9 /> :
                                                            <></>
                        }

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}