import axios from "axios";
import { useContext, useEffect, useState } from "react"

const Kabupaten = (id) => {
    if (id) {
        return axios.get('https://dev.farizdotid.com/api/daerahindonesia/kota/' + id).then((res) => {
            return res
        }).catch((e) => { return (e) });
    } else {
        return axios.get('https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=11').then((res) => {
            return res
        }).catch((e) => { return (e) });
    }

}
export default Kabupaten;