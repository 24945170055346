import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import Kecamatan from "../../Kecamatan";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Jagung } from '../../../../img/jagung.svg';
import { ReactComponent as Padi } from '../../../../img/padi.svg';
export default function Step1({ komoditi, setKomoditi }) {
    const navigate = useNavigate();
    const [kabupatenSelected, setKabupatenSelected] = useState('');
    const [kecamatanSelected, setKecamatanSelected] = useState('');
    const [nama, setNama] = useState();
    const [pengalaman, setPengalaman] = useState();
    const [kabupaten, setKabupaten] = useState([]);
    const [kecamatan, setKecamatan] = useState([]);
    const { axios } = useContext(apiContext);
    const fetchKecatamatan = async (id) => {
        const { data } = await Kecamatan(id = id);
        setKecamatan(data?.kecamatan);
    }
    const fetchKabupaten = async () => {
        const { data } = await Kabupaten();
        setKabupaten(data?.kota_kabupaten);
    }
    const onKabupatenSelected = (value) => {
        setKabupatenSelected(value);
        fetchKecatamatan(value);
    }
    useEffect(() => {
        fetchKabupaten()
    }, [])

    const submitForm = async () => {
        const data = {
            nama: nama,
            kabupaten: kabupatenSelected,
            kecamatan: kecamatanSelected,
            komoditi: komoditi,
            pengalaman: pengalaman,
        }
        await axios.post('/komoditi-post', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${res.data.id}/2`)
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <h3 className="fw-bold">Pilih Komoditi</h3>
            <Box className="row mb-5 mt-4" sx={{ gap: '12px' }}>
                <Box className={`rounded komoditi-button ${komoditi === 1 ? 'active' : ''} d-flex p-3 col-md-4`} onClick={() => setKomoditi(1)} sx={{ gap: '22px' }}>
                    <Jagung width={70} />
                    <h4 className="m-0 fw-bolder align-self-center">Jagung</h4>
                </Box>
                <Box className={`rounded komoditi-button ${komoditi === 2 ? 'active' : ''} d-flex p-3 col-md-4`} onClick={() => setKomoditi(2)} sx={{ gap: '22px' }}>
                    <Padi width={70} />
                    <h4 className="m-0 fw-bolder align-self-center">Padi</h4>
                </Box>
            </Box>
            <Box className="row">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pilih Kabupaten</p>
                    <FormControl fullWidth>
                        <InputLabel>Kabupaten</InputLabel>
                        <Select
                            value={kabupatenSelected}
                            label="Kabupaten"
                            onChange={(e) => onKabupatenSelected(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        >
                            {kabupaten.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.id}>{item.nama}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pilih Kecamatan</p>
                    <FormControl fullWidth>
                        <InputLabel>Kecamatan</InputLabel>
                        <Select
                            value={kecamatanSelected}
                            label="Kabupaten"
                            onChange={(e) => setKecamatanSelected(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        >
                            {kecamatan.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.id}>{item.nama}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                {/* <Box className="form-group col-md-6">
                    <p className="fw-bold">Pilih Jenis Lahan</p>
                    <FormControl fullWidth>
                        <InputLabel>Lahan</InputLabel>
                        <Select
                            value={jenis}
                            label="Lahan"
                            onChange={(e) => setJenis(e.target.value)}
                        >
                            <MenuItem value="milik sendiri">Milik Sendiri</MenuItem>
                            <MenuItem value="sewa">Sewa</MenuItem>
                            <MenuItem value="gabungan">Gabungan</MenuItem>
                            <MenuItem value="bagi hasil">Bagi Hasil</MenuItem>
                        </Select>
                    </FormControl>
                </Box> */}
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Nama</p>
                    <TextField fullWidth onChange={(e) => setNama(e.target.value)} variant="outlined" placeholder="Ahmad Azrul" />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pengalaman</p>
                    <TextField fullWidth type="number" onChange={(e) => setPengalaman(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold">Tahun</span>
                        )
                    }} />
                </Box>

            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}