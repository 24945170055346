import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import logo from '../../img/logo.jpeg';
import { ReactComponent as Login } from '../../img/login.svg';
import { ReactComponent as Info } from '../../img/info.svg';
import { ReactComponent as Jagung } from '../../img/jagung.svg';
import { ReactComponent as Padi } from '../../img/padi.svg';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
function Header({ setKategori }) {
    const [komoditi, setKomoditi] = React.useState("jagung");
    const onChangeKomoditi = (value)=>{
        setKomoditi(value);
        setKategori(value);
    }
    return (
        <React.Fragment>
            <Toolbar
                sx={{ justifyContent: 'space-between', overflowX: 'auto', py: 1 }} xs={{ borderBottom: 1, borderColor: 'divider', top: 0, width: '100%', py: 0.5 }} className="bg-white" >
                <div className='d-flex w-100 '>
                    <Box className="me-auto d-flex"><img src={logo} width={50} height={55}/>
                        <div className='align-self-center ms-2 mt-2'>
                            <p className='m-0 h6'>Dinas Pertanian dan Perkebunan</p>
                            <p className='m-0 text-success h4 fw-bold'>Provinsi Aceh</p>
                    </div>
                    </Box>
                    <FormControl style={{ width: '140px' }} size="small" className='mt-1'>
                        <Select variant='outlined' value={komoditi} onChange={e => onChangeKomoditi(e.target.value)} label="Komoditi" sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }} displayEmpty>
                            <MenuItem value="">Komoditi</MenuItem>

                            <MenuItem value="jagung"><Jagung fill='#0FA958' width={30} /> Jagung</MenuItem>
                            <MenuItem value="padi"><Padi fill='#0FA958' width={30} /> Padi</MenuItem>
                            <MenuItem value="semua">Semua</MenuItem>
                        </Select>
                    </FormControl>

                    <Button className='bg-transparent text-dark text-capitalize fs-5'>
                        Panduan
                        <Info fill='#0FA958' className='ms-2' width={24} />
                    </Button>
                    <Link to={`/login`} className='text-decoration-none text-white me-4'>
                        <Button className='bg-transparent text-dark text-capitalize fs-5'>
                            Login
                            <Login fill='#0FA958' className='ms-2' width={30}/>
                        </Button>
                    </Link>
                </div>
            </Toolbar>

        </React.Fragment >
    );
}

export default Header;