
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';

import 'leaflet/dist/leaflet.css';
import jagung_aceh_tengah from '../../file/Jagung_Aceh Tengah.pdf';
import jagung_aceh_besar from '../../file/jagung_aceh besar.pdf';
import jagung_pidie from '../../file/Jagung_Pidie.pdf';
import jagung_bireun from '../../file/Jagung_Bireun.pdf';
import jagung_nagan_raya from '../../file/Jagung_Nagan Raya.pdf';
import jagung_aceh_jaya from '../../file/Jagung_Aceh Jaya.pdf';
import jagung_aceh_bener_meriah from '../../file/Jagung_Bener Meriah.pdf';
import jagung_pidie_jaya from '../../file/Jagung_Pidie Jaya.pdf';

import padi_aceh_tengah from '../../file/Padi_Aceh Tengah.pdf';
import padi_aceh_besar from '../../file/Padi_Aceh Besar.pdf';
import padi_pidie from '../../file/Padi_Pidie.pdf';
import padi_bireun from '../../file/Padi_Bireun.pdf';
import padi_nagan_raya from '../../file/Padi_Nagan Raya.pdf';
import padi_aceh_jaya from '../../file/Padi_Aceh Jaya.pdf';
import padi_aceh_bener_meriah from '../../file/Padi_Bener Meriah.pdf';
import padi_pidie_jaya from '../../file/Padi_Pidie Jaya.pdf';
import { GeoJSON, MapContainer, TileLayer, useMap, ZoomControl } from 'react-leaflet'

import { ReactComponent as SearchIcon } from '../../img/search.svg';
import { ReactComponent as Show } from '../../img/show.svg';
import { ReactComponent as Home } from '../../img/home.svg';
import { ReactComponent as Print } from '../../img/print.svg';
import { ReactComponent as Analytics } from '../../img/analytics.svg';
import { ReactComponent as Peta } from '../../img/peta_aceh.svg';
import { ReactComponent as Layer } from '../../img/layer.svg';
import { ReactComponent as Agenda } from '../../img/agenda.svg';
import { ReactComponent as Point } from '../../img/point.svg';
import { ReactComponent as Theme } from '../../img/theme.svg';
import { ReactComponent as Compas } from '../../img/compas_colored.svg';
import { ReactComponent as Opacity } from '../../img/opacity.svg';
import MapIcon from '../../img/mapIcon.png';
import m1106 from '../../geoJson/m1106.json';
import m1108 from '../../geoJson/m1108.json';
import m1109 from '../../geoJson/m1109.json';
import m1110 from '../../geoJson/m1110.json';
import m1115 from '../../geoJson/m1115.json';
import m1116 from '../../geoJson/m1116.json';
import m1117 from '../../geoJson/m1117.json';
import m1118 from '../../geoJson/m1118.json';
import Popup from 'reactjs-popup';
import { Bar } from 'react-chartjs-2';
import a from '../../graph/Aset Petani.PNG'
import b from '../../graph/Data Prosentase Komoditi.PNG'
import c from '../../graph/Data Sebaran Lokasi Survey.PNG'
import d from '../../graph/Harga Jagung.PNG'
import e from '../../graph/Informasi Terkait Tenaga Kerja.PNG'
import f from '../../graph/Jenis Benih Jagung.PNG'
import g from '../../graph/Jenis Benih Padi.PNG'
import h from '../../graph/Jenis dan Jumlah Pestisida.PNG'
import i from '../../graph/Jenis dan Jumlah Pupuk.PNG'
import j from '../../graph/Jumlah Kebutuhan Benih Per Tahun.PNG'
import k from '../../graph/Jumlah Petani.PNG'
import l from '../../graph/Kepemilikan Lahan.PNG'
import m from '../../graph/Kesesuaian Lahan.PNG'
import n from '../../graph/Kesuburan Tanah.PNG'
import o from '../../graph/Ketersediaan Air.PNG'
import p from '../../graph/Luas Lahan Usaha.PNG'
import q from '../../graph/Mutu Benih.PNG'
import r from '../../graph/Pendapatan Usaha Tani.PNG'
import s from '../../graph/Penyimpanan Pasca Panen.PNG'
import t from '../../graph/Persepsi Perubahan Iklim.PNG'
import u from '../../graph/Perubahan Iklim 5 Tahun Terakhir.PNG'
import v from '../../graph/Perubahan Iklim Terhadap Pola Tanam.PNG'
import w from '../../graph/Perubahan Iklim Terhadap Usaha Tani.PNG'
import x from '../../graph/Pola Tanam.PNG'
import y from '../../graph/Rantai Pasok Jagung dari Petani.PNG'
import z from '../../graph/Rantai Pasok Padi dari Petani.PNG'
import aa from '../../graph/Rata-Rata Modal Usaha Per Musim Tanam.PNG'
import bb from '../../graph/Sumber Modal Per Musim Tanam.PNG'
import cc from '../../graph/Takaran Pupuk.PNG'
import dd from '../../graph/Teknologi yang Diterapkan.PNG'
import Chart from 'chart.js/auto';
import Control from 'react-leaflet-custom-control';
import {Button, FormControl, IconButton, InputAdornment, Slider, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
export default function Map({ open,setOpen,kategori,setPeta,peta,fullHeight,border=false }) {
    const location = useLocation();
    const [opacities, setOpacities] = useState(50);
    const [reset, setReset] = useState(false);
    const [data, setData] = useState(false);
    const [sm1106, setSm1106] = useState(true);
    const [sm1108, setSm1108] = useState(true);
    const [sm1109, setSm1109] = useState(true);
    const [sm1110, setSm1110] = useState(true);
    const [sm1115, setSm1115] = useState(true);
    const [sm1116, setSm1116] = useState(true);
    const [sm1117, setSm1117] = useState(true);
    const [sm1118, setSm1118] = useState(true);

    const [pm1106, setPm1106] = useState(false);
    const [pm1108, setPm1108] = useState(false);
    const [pm1109, setPm1109] = useState(false);
    const [pm1110, setPm1110] = useState(false);
    const [pm1115, setPm1115] = useState(false);
    const [pm1116, setPm1116] = useState(false);
    const [pm1117, setPm1117] = useState(false);
    const [pm1118, setPm1118] = useState(false);

    const printPdf = () => {
        if (pm1106) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_aceh_tengah : padi_aceh_tengah;
            link.download = 'Aceh Tengah';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1108) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_aceh_besar : padi_aceh_besar;
            link.download = 'Aceh Besar';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1109) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_pidie : padi_pidie;
            link.download = 'Aceh Pidie';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1110) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_bireun : padi_bireun;
            link.download = 'Bireun';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1115) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_nagan_raya : padi_nagan_raya;
            link.download = 'Nagan Raya';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1116) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_aceh_jaya : padi_aceh_jaya;
            link.download = 'Aceh Jaya';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1117) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_aceh_bener_meriah : padi_aceh_bener_meriah;
            link.download = 'Aceh Bener Meriah';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (pm1118) {
            const link = document.createElement('a');
            link.href = kategori === 'jagung' ? jagung_pidie_jaya : padi_pidie_jaya;
            link.download = 'Pidie Jaya';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const [printState, setPrintState] = useState(false);

    const changeData = (e) => {
        setData(e.sourceTarget.feature.properties)
    }
    function onEachFeature(feature, layer) {
        if (feature.properties) {
            var props = feature.properties;

            if (kategori === 'semua') {
                layer.bindPopup(`
            <div class='button-primary p-2 px-4 mb-2 text-white rounded-top h5'>Jagung</div>
            <div class="p-2">
            <p class="row m-0"><span class='col-md-5'>Nama Daerah </span><span class='col-md-1'>:</span><span class='col text-start'>${props.name}</span> </p>
            <p class="row m-0"><span class='col-md-5'>Luas Panen </span><span class='col-md-1'>:</span><span class='col text-start'>${props.luasPanen.jagung} Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produktivitas </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produktivitas.jagung} Ton/Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produksi </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produksi.jagung} Ton</span></p>
            </div>
                <div class='button-primary p-2 px-4 mb-2 text-white h5'>Padi</div>
            <div class="p-2">
            <p class="row m-0"><span class='col-md-5'>Nama Daerah </span><span class='col-md-1'>:</span><span class='col text-start'>${props.name}</span> </p>
            <p class="row m-0"><span class='col-md-5'>Luas Panen </span><span class='col-md-1'>:</span><span class='col text-start'>${props.luasPanen.padi} Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produktivitas </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produktivitas.padi} Ton/Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produksi </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produksi.padi} Ton</span></p>
            </div>
            `)
            } else {
                layer.bindPopup(`
            <div class='button-primary p-2 px-4 mb-2 text-white rounded-top h5'>${kategori}</div>
            <div class="p-2">
            <p class="row m-0"><span class='col-md-5'>Nama Daerah </span><span class='col-md-1'>:</span><span class='col text-start'>${props.name}</span> </p>
            <p class="row m-0"><span class='col-md-5'>Luas Panen </span><span class='col-md-1'>:</span><span class='col text-start'>${props.luasPanen[kategori]} Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produktivitas </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produktivitas[kategori]} Ton/Ha</span></p>
            <p class="row m-0"><span class='col-md-5'>Produksi </span><span class='col-md-1'>:</span><span class='col text-start'>${props.produksi[kategori]} Ton</span></p>
            </div>
            `)
            }
            layer.on({
                click: changeData
            });
        }
    }

    const SliderFragment = (setOpacities, opacities) => {

        const handleChange = (event, newValue) => {
            setOpacities(newValue);
        };

        return (
            <React.Fragment>
                <Slider
                    value={opacities}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    max={50}
                    onChange={handleChange}
                />
            </React.Fragment>
        );
    };
    function style(feature) {
        if (feature.properties) {
            var props = feature.properties;
            return {
                color: props.color,
                opacity: 0,
                fillOpacity: opacities + '%'
            };
        }

    }
    return (
        <MapContainer className='shadow ' closePopupOnClick={false} key={reset} center={[4.931872, 96.173769]} zoom={9} zoomControl={false} style={{ height: fullHeight? '90vh':'50vh', width: "100%",borderRadius:border? '12px':'0' }}>

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <GeoJSON attribution="&copy; credits due..." data={[
                sm1106 ? m1106 : [],
                sm1108 ? m1108 : [],
                sm1109 ? m1109 : [],
                sm1110 ? m1110 : [],
                sm1115 ? m1115 : [],
                sm1116 ? m1116 : [],
                sm1117 ? m1117 : [],
                sm1118 ? m1118 : [],
            ]} key={[kategori, sm1106,
                sm1108,
                sm1109,
                sm1110,
                sm1115,
                sm1116,
                sm1117,
                sm1118, opacities]} onEachFeature={onEachFeature} style={style}>
            </GeoJSON>
            <ZoomControl position="bottomright" zoomInText="+" zoomOutText="-" className="button-primary" />
            <Control prepend position='topleft'>
                <FormControl className='ms-5'>
                    <TextField
                        value={2022}
                        size='small'
                        variant='outlined'
                        type='number'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                width: '120px', padding: '0',
                                fontSize: '14px',
                                borderRadius: '10px',
                                "&.Mui-focused": {
                                    "& .MuiInputAdornment-root": {
                                        color: "white",
                                        backgroundColor: (theme) => theme.palette.primary.main
                                    }
                                }
                            },
                        }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                                padding: '0'
                            },
                            startAdornment: (
                                <Box position="start" className='button-primary text-white me-2' sx={{ height: '100%', padding: '7px 8px', fontSize: '14px', borderRadius: '10px 0 0 10px', }}>
                                    <Typography variant="caption">Tahun</Typography>
                                </Box>
                            )
                        }}
                    />
                    <Box className="button-primary rounded mt-4 py-1" sx={{ width: '40px' }}>
                        <a className='bg-transparent border-0' href={location.pathname} style={{ padding: '1px 6px' }}>
                            <Home width={30} className=" list-button" />
                        </a>
                        <Popup modal trigger={
                            <button className='bg-transparent border-0'>
                                <Analytics width={30} className=" list-button" />
                            </button>
                        } position="right center" {...{ contentStyle: { maxHeight: '80vh', overflow: 'auto',borderRadius:'12px' } }}>
                            <div className='text-center'>
                                <img src={a} />
                                <img src={b} />
                                <img src={c} />
                                <img src={d} />
                                <img src={e} />
                                <img src={f} />
                                <img src={g} />
                                <img src={h} />
                                <img src={i} />
                                <img src={j} />
                                <img src={k} />
                                <img src={l} />
                                <img src={m} />
                                <img src={o} />
                                <img src={p} />
                                <img src={q} />
                                <img src={r} />
                                <img src={s} />
                                <img src={t} />
                                <img src={u} />
                                <img src={v} />
                                <img src={w} />
                                <img src={x} />
                                <img src={y} />
                                <img src={z} />
                                <img src={aa} />
                                <img src={bb} />
                                <img src={cc} />
                                <img src={dd} />
                                {/* <Bar
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Chart',
                                                    },
                                                },
                                            }}
                                            data={{
                                                labels: ['Dec 1', 'Dec 2', 'Dec 3', 'Dec 4', 'Dec 5'],
                                                datasets: [
                                                    {
                                                        label: kategori,
                                                        data: ['100', '20', '50', '110', '70'],
                                                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                    },

                                                ],
                                            }}
                                        /> */}
                            </div>
                        </Popup>

                        <button className='bg-transparent border-0' onClick={(e) => { e.stopPropagation(); setPrintState(!printState) }}>
                            <Print width={30} className=" list-button" />
                        </button>
                        <div className={`bg-white position-absolute rounded shadow-sm ${printState ? 'd-block' : 'd-none'}`} style={{ width: '356px', padding: '22px', marginLeft: '45px', top: '20%' }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <Button onClick={() => setPm1106(!pm1106)} fullWidth variant={pm1106 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1106.properties.color }}></div>{m1106.properties.name}</Button>
                            <Button onClick={() => setPm1108(!pm1108)} fullWidth variant={pm1108 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1108.properties.color }}></div>{m1108.properties.name}</Button>
                            <Button onClick={() => setPm1109(!pm1109)} fullWidth variant={pm1109 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1109.properties.color }}></div>{m1109.properties.name}</Button>
                            <Button onClick={() => setPm1110(!pm1110)} fullWidth variant={pm1110 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1110.properties.color }}></div>{m1110.properties.name}</Button>
                            <Button onClick={() => setPm1115(!pm1115)} fullWidth variant={pm1115 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1115.properties.color }}></div>{m1115.properties.name}</Button>
                            <Button onClick={() => setPm1116(!pm1116)} fullWidth variant={pm1116 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1116.properties.color }}></div>{m1116.properties.name}</Button>
                            <Button onClick={() => setPm1117(!pm1117)} fullWidth variant={pm1117 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1117.properties.color }}></div>{m1117.properties.name}</Button>
                            <Button onClick={() => setPm1118(!pm1118)} fullWidth variant={pm1118 ? 'contained' : 'outlined'} className='text-start d-block'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1118.properties.color }}></div>{m1118.properties.name}</Button>
                            <div className='mt-3 text-center'>
                                <Button variant="contained" onClick={(e) => printPdf()}>Cetak</Button>
                            </div>
                        </div>
                        <button className='bg-transparent border-0' onClick={(e) => { e.stopPropagation(); setPeta(!peta) }}>
                            <Peta width={30} className=" list-button" />
                        </button>
                        <div className={`bg-white position-absolute rounded shadow-sm ${peta ? 'd-block' : 'd-none'}`} style={{ width: '356px', padding: '22px', marginLeft: '45px', top: fullHeight ? '50%':'0%' }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <Button onClick={() => setSm1106(!sm1106)} fullWidth variant={sm1106 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1106.properties.color }}></div>{m1106.properties.name}</Button>
                            <Button onClick={() => setSm1108(!sm1108)} fullWidth variant={sm1108 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1108.properties.color }}></div>{m1108.properties.name}</Button>
                            <Button onClick={() => setSm1109(!sm1109)} fullWidth variant={sm1109 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1109.properties.color }}></div>{m1109.properties.name}</Button>
                            <Button onClick={() => setSm1110(!sm1110)} fullWidth variant={sm1110 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1110.properties.color }}></div>{m1110.properties.name}</Button>
                            <Button onClick={() => setSm1115(!sm1115)} fullWidth variant={sm1115 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1115.properties.color }}></div>{m1115.properties.name}</Button>
                            <Button onClick={() => setSm1116(!sm1116)} fullWidth variant={sm1116 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1116.properties.color }}></div>{m1116.properties.name}</Button>
                            <Button onClick={() => setSm1117(!sm1117)} fullWidth variant={sm1117 ? 'contained' : 'outlined'} className='text-start d-block mb-1'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1117.properties.color }}></div>{m1117.properties.name}</Button>
                            <Button onClick={() => setSm1118(!sm1118)} fullWidth variant={sm1118 ? 'contained' : 'outlined'} className='text-start d-block'><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1118.properties.color }}></div>{m1118.properties.name}</Button>
                        </div>
                        <Popup trigger={
                            <button className='bg-transparent border-0'>
                                <Layer width={30} className=" list-button" />
                            </button>
                        } position="right center" {...{ contentStyle: { width: '356px', padding: '22px' } }}>
                            {
                                data ?
                                    <>
                                        <div>Kabupaten {data.name}</div>
                                        <hr className='mb-3' />
                                        <div className='row'>
                                            <span className='col-md-5'>Luas Panen </span>
                                            <span className='col'>{data.luasPanen[kategori]}</span>
                                        </div>
                                        <hr className='m-1' />
                                        <div className='row'>
                                            <span className='col-md-5'>Produktivitas</span>
                                            <span className='col'>{data.produktivitas[kategori]}</span>
                                        </div>
                                        <hr className='m-1' />
                                        <div className='row'>
                                            <span className='col-md-5'>Produksi</span>
                                            <span className='col'>{data.produksi[kategori]}</span>
                                        </div>
                                    </>
                                    : <></>
                            }

                        </Popup>

                        <button className='bg-transparent border-0' onClick={(e) => { e.stopPropagation(); setOpen(!open) }}>
                            <Agenda width={30} />
                        </button>
                        <div className={`bg-white position-absolute rounded shadow-sm ${open ? 'd-block' : 'd-none'}`} style={{ width: '256px', padding: '22px', marginLeft: '45px', top: fullHeight ? '50%' : '25%' }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            <div>
                                <div className='my-3 d-flex'>
                                    <div>
                                        <Opacity width={30} />
                                        <p>Opacity</p>
                                    </div>
                                    {SliderFragment(setOpacities, opacities)}
                                </div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1106.properties.color }}></div>{m1106.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1108.properties.color }}></div>{m1108.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1109.properties.color }}></div>{m1109.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1110.properties.color }}></div>{m1110.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1115.properties.color }}></div>{m1115.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1116.properties.color }}></div>{m1116.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1117.properties.color }}></div>{m1117.properties.name}</div>
                                <div><div className='p-1 d-inline-block me-2' style={{ width: '12px', backgroundColor: m1118.properties.color }}></div>{m1118.properties.name}</div>
                            </div>
                        </div>

                    </Box>
                </FormControl>
            </Control>
            <Control prepend position='topright'>
                <Box className="d-flex flex-column">
                    <FormControl>
                        <TextField placeholder="Pencarian" className='bg-white'
                            variant='standard'
                            style={{ borderRadius: "20px 0 0 20px" }}

                            InputProps={{
                                style: {
                                    padding: 0,
                                    border: 0,
                                },

                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchIcon fill='#aaa' />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <button className='button-primary' style={{ border: '0', padding: '7px 8px', }}>
                                        <Show fill='#fff' width={24} />
                                    </button>
                                )
                            }}
                        />
                    </FormControl>
                    <Box className="d-flex flex-column mt-4" >
                        <button className='button-primary border-0 rounded ms-auto' style={{ padding: '0 6px 0 6px', width: '35px' }} >
                            <Theme width={20} height={35} />
                        </button>
                        <button className='button-primary border-0 rounded ms-auto mt-2' style={{ padding: '0 6px 0 6px', width: '35px' }}>
                            <Compas width={20} height={35} />
                        </button>
                    </Box>
                </Box>

            </Control>
            <Control prepend position='bottomright'>
                <button className='button-primary border-0 rounded' style={{ padding: '0 6px 0 6px' }} onClick={() => setReset(!reset)}>
                    <Point width={20} height={35} />
                </button>
            </Control>
        </MapContainer>
    )
}