import { Box } from "@mui/system";
import HeaderUser from "./nav/HeaderUser";
import SoapIcon from '@mui/icons-material/Soap';
import Map from "../components/Map";
import { useContext, useEffect, useReducer, useState } from "react";
import Copyright from "./nav/Copyright";
import ProgressStep from "../components/ProgressStep";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Step1 from "../components/form/komoditi/Step1";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiContext } from "../../api";
import { Grid } from "gridjs-react";
import Kabupaten from "../components/Kabupaten";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { _ } from "gridjs-react";
import Swal from "sweetalert2";
export default function DataList() {
    const swalStyles = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-2'
        },
        buttonsStyling: false
    })
    const reducer = (state, action) => {
        switch (action.type) {
            case 'append':
                return [...state, action.payload]
        }
    }
    const navigate = useNavigate();
    const { axios } = useContext(apiContext);
    const [data, setData] = useReducer(reducer, []);
    const getKabupaten = async (id) => {
        const { data } = await Kabupaten(id);
        return data
    }
    const redirect = (id) => {
        navigate(`/user/data/${id}/1`)
    }
    function onDelete(id) {
        swalStyles.fire({
            title: 'Apa Kamu Yakin?',
            text: "Data tidak dapat dikembalikan jika dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Hapus!',
            cancelButtonText: 'Batal',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                swalStyles.fire(
                    'Berhasil Dihapus!',
                    '',
                    'success'
                )
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalStyles.fire(
                    'Dibatalkan',
                    '',
                    'error'
                )
            }
        })
    }
    const fetchData = async () => {
        const { data } = await axios.get('/komoditi');
        if (data) {
            data.map(async (item, index) => {
                const res = await Kabupaten(item.kabupaten);
                setData({ type: 'append', payload: { nama: item.nama, kabupaten: res.data.nama, id: item.id } })
            })
        }
    }
    useEffect(() => {
        fetchData();
        console.log(data)
    }, [])
    return (
        <Box className="d-flex">
            <HeaderUser title={'Data'} subTitle={'Silahkan lakukan update data komoditi di tiap daerah'} />
            <Box component={'main'} className="me-5 w-100 pb-5 " style={{ marginTop: '150px' }}>
                <Grid
                    data={
                        data?.map((item, index) =>
                            [
                                index += 1, item.nama, item.kabupaten, _(<><Button className="btn bg-info bg-gradient me-2 text-white" onClick={() => (redirect(item.id))}><EditIcon /></Button> <Button className="btn bg-danger bg-gradient text-white" onClick={()=>(onDelete(item.id))}><DeleteIcon /></Button></>)
                            ]
                        )
                    }
                    columns={['No', 'Nama', 'Kabupaten', 'Opsi']}
                    search={true}
                    pagination={{
                        limit: 1,
                    }}
                />
            </Box>
        </Box>
    )
}