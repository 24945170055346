import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Semprot } from '../../../../img/semprot.svg';
import { ReactComponent as Cangkul } from '../../../../img/cangkul.svg';
import { ReactComponent as Pemotong } from '../../../../img/pemotong.svg';
import { ReactComponent as Tanam } from '../../../../img/tanam.svg';
import { ReactComponent as Traktor } from '../../../../img/traktor.svg';
import { ReactComponent as Garu } from '../../../../img/garu.svg';
import { ReactComponent as Pompa } from '../../../../img/pompa.svg';
import { ReactComponent as Perontok } from '../../../../img/perontok.svg';
import { ReactComponent as Panen } from '../../../../img/panen.svg';

export default function Step9() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [asset, setAsset] = useState('');
    const [diterapkan, setDiterapkan] = useState('');
    const { axios } = useContext(apiContext);

    const fetchData = async () => {
        const { data } = await axios.get(`/infoasset-get/${id}`);
        if (data.length) {
            setAsset(data.asset);
            setDiterapkan(data.diterapkan);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const submitForm = async () => {
        const data = {
            asset: asset,
            diterapkan: diterapkan,
            petani_id: id
        }
        await axios.post('/infoasset', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/9`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    const Jagung = [
        'Agen Desa',
        'Agen Kecamatan',
        'Agen Kabupaten',
        'Perusahaan'
    ];
    const Padi = [
        'Tidak Dijual',
        'Agen Desa',
        'Agen Kecamatan',
        'Kilang Padi'
    ]
    return (
        <>
            <Box className="form-group">
                <p className="fw-bold">Asset Dimiliki</p>
                <Box className='d-flex' style={{ gap: '14px' }}>
                    <Button variant="outlined" className="text-dark text-capitalize"><Semprot className="me-2" /> Alat Semprot</Button>
                    <Button variant="outlined" className="text-dark text-capitalize"><Cangkul className="me-2" /> Cangkul</Button>
                    <Button variant="outlined" className="text-dark text-capitalize"><Pemotong className="me-2" /> Mesin Pemotong Rumput</Button>
                </Box>
                <Box className='d-flex mt-4' style={{ gap: '14px' }}>
                   
                    <Button variant="outlined" className="text-dark text-capitalize"><Tanam className="me-2" /> Mesin Tanam</Button>
                    <Button variant="outlined" className="text-dark text-capitalize"><Traktor className="me-2" /> Traktor</Button>
                    <Button variant="outlined" className="text-dark text-capitalize"><Garu className="me-2" /> Garu</Button>
                    <Button variant="outlined" className="text-dark text-capitalize"><Pompa className="me-2" /> Mesin Pompa Air</Button>
                </Box>
            </Box>
            <Box className="mt-4 form-group">
                <p className="fw-bold">Pinjaman Bank</p>

            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}