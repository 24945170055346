import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step8() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [pasok, setPasok] = useState('');
    const [modal, setModal] = useState('');
    const [pinjaman, setPinjaman] = useState('');
    const [lembaga, setLembaga] = useState('');
    const [hutang_nb, setHutang] = useState('');
    const [bantuan, setBantuan] = useState('');
    const [komoditi, setKomoditi] = useState('');
    const { axios } = useContext(apiContext);
    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9.]/g, "");
    const fetchKomoditi = async () => {
        const { data } = await axios.get(`/komoditi-get/${id}`);
        if (data) {
            setKomoditi(data.komoditi);
        }
    }
    const fetchData = async () => {
        const { data } = await axios.get(`/infomodal-get/${id}`);
        if (data.length) {
            setPasok(data.pasok);
            setModal(data.modal);
            setPinjaman(data.pinjaman);
            setLembaga(data.lembaga);
            setHutang(data.hutang_nb);
            setBantuan(data.bantuan);
        }
    }

    useEffect(() => {
        fetchData();
        fetchKomoditi();
    }, [])

    const submitForm = async () => {
        const data = {
            pasok: pasok,
            modal: parseFloat(modal.replace(',', '')),
            pinjaman: parseFloat(pinjaman.replace(',', '')),
            lembaga: parseFloat(lembaga.replace(',', '')),
            hutang_nb: parseFloat(hutang_nb.replace(',', '')),
            bantuan: parseFloat(bantuan.replace(',', '')),
            petani_id: id
        }
        await axios.post('/infomodal', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/9`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    const Jagung = [
        'Agen Desa',
        'Agen Kecamatan',
        'Agen Kabupaten',
        'Perusahaan'
    ];
    const Padi = [
        'Tidak Dijual',
        'Agen Desa',
        'Agen Kecamatan',
        'Kilang Padi'
    ]
    return (
        <>
            <Box className="row">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Rantai Pasok</p>
                    <FormControl fullWidth>
                        <InputLabel>Rantai Pasok</InputLabel>
                        <Select
                            value={pasok}
                            label="Pasok"
                            onChange={(e) => setPasok(e.target.value)}
                        >
                            {
                                komoditi == 1 ?
                                    Jagung.map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                    : Padi.map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })

                            }

                        </Select>
                    </FormControl>
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Modal Sekali Produksi</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(modal))} onChange={(e) => setModal(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Pinjaman Bank</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(pinjaman))} onChange={(e) => setPinjaman(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Lembaga Keuangan</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(lembaga))} onChange={(e) => setLembaga(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        )
                    }} />
                </Box>
            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Hutang Non-Bank</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(hutang_nb))} onChange={(e) => setHutang(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-md-6">
                    <p className="fw-bold">Bantuan Pemerintah</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(bantuan))} onChange={(e) => setBantuan(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        )
                    }} />
                </Box>
            </Box>

            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}