import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step3() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [tanggungan, setTanggungan] = useState('');
    const [kondisi, setKondisi] = useState('');
    const [kesesuaian, setKesesuaian] = useState('');
    const [air, setAir] = useState('');
    const [pupuk, setPupuk] = useState('');
    const { axios } = useContext(apiContext);
    const fetchData = async () => {
        const { data } = await axios.get(`/surveylahan-get/${id}`);
        if (data.lenght) {
            setTanggungan(data.tanggungan);
            setKondisi(data.kondisi);
            setKesesuaian(data.kesesuaian);
            setAir(data.air);
            setPupuk(data.pupuk);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const submitForm = async () => {
        const data = {
            tanggungan: tanggungan,
            kondisi: kondisi,
            kesesuaian: kesesuaian,
            air: air,
            pupuk: pupuk,
            petani_id: id
        }
        await axios.post('/surveylahan', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/4`);
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <Box className="form-group col-md-4">
                <p className="fw-bold">Tanggungan</p>
                <TextField fullWidth type="number" value={tanggungan} onChange={(e) => setTanggungan(e.target.value)} variant="outlined" InputProps={{
                    endAdornment: (
                        <span className="fw-bold" >Orang</span>
                    )
                }} />
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana menurut pendapat anda terkait dengan kondisi kesuburan lahan yang saudara usahakan untuk usahatani (padi/jagung)</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Kondisi Lahan</InputLabel>
                    <Select
                        value={kondisi}
                        label="kondisi"
                        onChange={(e) => setKondisi(e.target.value)}
                    >
                        <MenuItem value={'s'}>Subur</MenuItem>
                        <MenuItem value={'ss'}>Sangat Subur</MenuItem>
                        <MenuItem value={'ks'}>Kurang Subur</MenuItem>
                        <MenuItem value={'ts'}>Tidak Subur</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana menurut pendapat saudara terkait dengan kesesuaian lahan yang diusahakan dengan komoditas padi/jagung?</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Kesesuaian Lahan</InputLabel>
                    <Select
                        value={kesesuaian}
                        label="kesesuaian"
                        onChange={(e) => setKesesuaian(e.target.value)}
                    >
                        <MenuItem value={'s'}>Sesuai</MenuItem>
                        <MenuItem value={'ss'}>Sangat Sesuai</MenuItem>
                        <MenuItem value={'ks'}>Kurang Sesuai</MenuItem>
                        <MenuItem value={'ts'}>Tidak Sesuai</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Bagaimana menurut pendapat anda terkait dengan ketersediaan air pada lahan usahatani (padi/jagung)?</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Ketersediaan Air</InputLabel>
                    <Select
                        value={air}
                        label="air"
                        onChange={(e) => setAir(e.target.value)}
                    >
                        <MenuItem value={'c'}>Cukup</MenuItem>
                        <MenuItem value={'sc'}>Sangat Cukup</MenuItem>
                        <MenuItem value={'kc'}>Kurang Cukup</MenuItem>
                        <MenuItem value={'tc'}>Tidak Cukup</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="form-group mt-4">
                <p className="fw-bold">Menurut anda, bagaimana takaran kebutuhan pupuk yang digunakan untuk menyuburkan lahan usahatani (padi/jagung)?</p>
                <FormControl fullWidth>
                    <InputLabel>Pilih Takaran Kebutuhan Pupuk</InputLabel>
                    <Select
                        value={pupuk}
                        label="pupuk"
                        onChange={(e) => setPupuk(e.target.value)}
                    >
                        <MenuItem value={'c'}>Cukup</MenuItem>
                        <MenuItem value={'sc'}>Sangat Cukup</MenuItem>
                        <MenuItem value={'kc'}>Kurang Cukup</MenuItem>
                        <MenuItem value={'tc'}>Tidak Cukup</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}