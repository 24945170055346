import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiContext } from '../../../api';
export const userContext = createContext()
function Profile(props) {
    const {axios} = useContext(apiContext)
    const [user, setUser] = useState(false);
    const profile = async () => {
        const res = await axios.post('/profile');
        setUser(res.data);
    }
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
       
        if (isLoggedIn) {
            profile();
        }
    }, [])

    return user ? (
        <userContext.Provider
            value={{
                user,profile
            }}>
            {props.children}
        </userContext.Provider>
    ) : ''
}
export default Profile;