import { useContext, useEffect, useState } from "react";
import Kabupaten from "../../Kabupaten";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { apiContext } from "../../../../api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function Step2() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jk, setJk] = useState('');
    const [kepemilikan, setKepemilikan] = useState('');
    const [pendapatan_usaha, setPendapatanUsaha] = useState(0);
    const [pendapatan, setPendapatan] = useState(0);
    const [pengeluaran, setPengeluaran] = useState(0);
    const [luas_lahan, setLuasLahan] = useState(0);
    const { axios } = useContext(apiContext);
    const addCommas = (num) =>
        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9.]/g, "");
    const fetchData = async () => {
        const { data } = await axios.get(`/biodata-get/${id}`);
        if (data.lenght) {
            setJk(data.jk);
            setKepemilikan(data.kepemilikan);
            setPendapatanUsaha(data.pendapatan_usaha);
            setPendapatan(data.pendapatan);
            setPengeluaran(data.pengeluaran);
            setLuasLahan(data.luas_lahan);
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const submitForm = async () => {
        const data = {
            jk: jk,
            kepemilikan: kepemilikan,
            pendapatan_usaha: parseFloat(pendapatan_usaha.replace(',', '')),
            pendapatan: parseFloat(pendapatan.replace(',', '')),
            pengeluaran: parseFloat(pengeluaran.replace(',', '')),
            luas_lahan: parseFloat(luas_lahan.replace(',', '')),
            petani_id: id
        }
        await axios.post('/biodata', data)
            .then((res) => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Berhasil disimpan',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate(`/user/data/${id}/3`)
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })
            })
    }
    return (
        <>
            <Box className="row">
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Pendapatan Usaha Tani</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(pendapatan_usaha))} onChange={(e) => setPendapatanUsaha(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        ),
                        endAdornment: (
                            <span className="fw-bold" style={{ whiteSpace: 'nowrap' }}>/ MT</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Pendapatan Petani</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(pendapatan))} onChange={(e) => setPendapatan(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        ),
                        endAdornment: (
                            <span className="fw-bold" style={{ whiteSpace: 'nowrap' }}>/ Bulan</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Jenis Kelamin</p>
                    <FormControl fullWidth>
                        <InputLabel>Pilih Jenis Kelamin</InputLabel>
                        <Select
                            value={jk}
                            label="jk"
                            onChange={(e) => setJk(e.target.value)}
                        >
                            <MenuItem value={'Laki-laki'}>Laki-laki</MenuItem>
                            <MenuItem value={'Perempuan'}>Perempuan</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

            </Box>
            <Box className="row mt-4">
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Pengeluaran Rumah Tangga</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(pengeluaran))} onChange={(e) => setPengeluaran(e.target.value)} variant="outlined" InputProps={{
                        startAdornment: (
                            <span className="fw-bold">Rp.</span>
                        ),
                        endAdornment: (
                            <span className="fw-bold" style={{ whiteSpace: 'nowrap' }}>/ Bulan</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Luas Lahan</p>
                    <TextField fullWidth value={addCommas(removeNonNumeric(luas_lahan))} onChange={(e) => setLuasLahan(e.target.value)} variant="outlined" InputProps={{
                        endAdornment: (
                            <span className="fw-bold" style={{ whiteSpace: 'nowrap' }}>Ha</span>
                        )
                    }} />
                </Box>
                <Box className="form-group col-lg-4">
                    <p className="fw-bold">Kepemilikan Lahan</p>
                    <FormControl fullWidth>
                        <InputLabel>Pilih Kepemilikan Lahan</InputLabel>
                        <Select
                            value={kepemilikan}
                            label="kepemilikan"
                            onChange={(e) => setKepemilikan(e.target.value)}
                        >
                            <MenuItem value={'Milik Sendiri'}>Milik Sendiri</MenuItem>
                            <MenuItem value={'Sewa'}>Sewa</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box className="text-end mt-4">
                <Button variant="contained" className="bg-green-light" onClick={() => submitForm()}>Lanjut</Button>
            </Box>
        </>
    )
}